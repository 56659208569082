html,
body,
#root {
  height: 100%;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px !important;
  letter-spacing: 0.3px;
  line-height: 1.6;
}
