@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;800&display=swap);
html,
body,
#root {
  height: 100%;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px !important;
  letter-spacing: 0.3px;
  line-height: 1.6;
}

.full-width-image{height:250px;width:250px;top:0;left:0}.image-text{margin:auto;padding-top:5px}.full-screen{flex-direction:column;margin:1rem 1rem 0 .5rem;top:0;width:-webkit-fill-available;width:stretch}.centered{text-align:center;margin:auto}.background{background-image:url(/static/media/bgnd.f16653ad.png);background-size:cover}
.full-width-image{height:250px;width:250px;top:0;left:0}.image-text{margin:auto;padding-top:5px}.full-screen{flex-direction:column;margin:1rem 1rem 0 .5rem;top:0;width:-webkit-fill-available;width:stretch}.centered{text-align:center;margin:auto}.background{background-image:url(/static/media/bgnd.f16653ad.png);background-size:cover}.feature-text{margin:auto;padding-top:5px;color:green}.change-text{margin:auto;padding-top:5px;color:orange}.fixed-text{margin:auto;padding-top:5px;color:blue}
